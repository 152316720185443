:root {
  --background: #282c32;
  --foreground: #2a5464;
  --button: #0a8186;
  --button-hover: #8fd880;
  --button-active: #f9f871;
  --button-active-color: #333;
}

.App {
  background-color: var(--background);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-sizing: content-box;
}
