.Form {
  background-color: var(--foreground);
  color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  border-radius: 5px;
}

@media (min-width: 600px) {
  .Form,
  input,
  button,
  .timerValue {
    font-size: 2em;
  }
}

@media (max-width: 599px) {
  .timerValue {
    font-size: 35px;
  }
}

.Form label {
  display: block;
  color: white;
  margin-right: 1em;
  font-family: monospace;
  margin-bottom: 5px;
}

.container h1 {
  text-align: center;
  color: #fff;
  font-weight: 100;
  font-size: 2em;
  margin-bottom: 10px;
}

.container .textInput {
  margin-bottom: 22px;
  margin: 0 20px 22px 20px;
  display: block;
}

input {
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
}

.Form .buttons {
  text-align: center;
  align-self: center;
  margin-bottom: 22px;
  margin-left: 20px;
  display: inline-block;
}

.Form button,
.Form button:visited {
  border: 2px solid transparent;
  margin-right: 1em;
  padding: 5px 22px;
  border-radius: 3px;
  background-color: var(--button);
  color: white;
}

.Form button:hover {
  background-color: #383c42;
  border-color: #999;
  cursor: pointer;
  box-sizing: border-box;
}

.Form .timerValueBlock {
  display: flex;
  justify-content: space-between;
}

.Form .timerValue {
  color: white;
  text-align: center;
  display: block;
}

.Form .spacer {
  min-width: 1em;
}

.Form .weight {
  min-width: 2em;
  display: inline-block;
  text-align: right;
}
